import "./assets/styles/styles.scss"
import "./index.scss"

window.addEventListener("scroll", () => {
  const sections = document.querySelectorAll("section")
  let currentSection

  sections.forEach((section) => {
    const sectionTop = section.offsetTop
    const sectionBottom = section.offsetTop + section.offsetHeight
    const scrollPosition = window.scrollY + window.innerHeight / 2

    if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
      currentSection = section
    }
  })

  if (!currentSection) return

  const navigationLinks = document.querySelectorAll(".page-navigation a")

  navigationLinks.forEach((link) => {
    link.removeAttribute("style")
    if (link.getAttribute("href") === `#${currentSection.id}`) {
      link.style.background = "var(--primary)"
    }
    if (currentSection.id === sections[1].id) {
      navigationLinks[0].style.background = "var(--primary)"
    }
  })
})

////////////////// Nav-Bar //////////////////

const navBar = document.querySelector("header nav")
const menuIcon = document.querySelector("#menu-icon")

menuIcon.addEventListener("click", () => {
  navBar.classList.toggle("active")
})

window.addEventListener("scroll", () => {
  navBar.classList.remove("active")
})

window.addEventListener("click", (event) => {
  if (!menuIcon.contains(event.target)) {
    navBar.classList.remove("active")
  }
})

////////////////// Form //////////////////
const form = document.querySelector("form")
const formElements = form.elements
const FORM_DATA_KEY = "formData"

const getFormData = () => {
  const data = { [FORM_DATA_KEY]: {} }
  for (const element of formElements) {
    if (element.name) {
      data[FORM_DATA_KEY][element.name] = element.value
    }
  }
  return data
}

form.onchange = () => {
  const data = getFormData()
  localStorage.setItem(FORM_DATA_KEY, JSON.stringify(data[FORM_DATA_KEY]))
}

const retrieveFormData = () => {
  if (localStorage.key(FORM_DATA_KEY)) {
    const savedData = JSON.parse(localStorage.getItem(FORM_DATA_KEY))
    if (savedData) {
      for (const element of formElements) {
        if (element.name in savedData) {
          element.value = savedData[element.name]
        }
      }
    }
  }
}

document.onload = retrieveFormData()

form.addEventListener("submit", (e) => {
  document.querySelectorAll("form .input").forEach((input) => {
    if (input.checkValidity()) {
      sessionStorage.setItem("test", document.querySelector("form #name").value)
    }
  })
})

//============== SCROLL REVEAL ANIMATION ==============//

const sr = ScrollReveal({
  origin: "top",
  distance: "60px",
  duration: 3000,
  delay: 200,
  mobile: true,
})

sr.reveal(`.page-navigation`, {
  origin: "right",
  distance: "160%",
  delay: 1200,
  duration: 2000,
})

sr.reveal(`.social-navigation`, {
  origin: "left",
  distance: "100%",
  delay: 1200,
  duration: 2000,
})

sr.reveal(`header`, {
  origin: "top",
  distance: "90px",
  delay: 100,
})

sr.reveal(`.home-text`, {
  origin: "left",
})
sr.reveal(`.home-image`, {
  origin: "right",
})
sr.reveal(`#steps`, {
  delay: 800,
  mobile: false,
})
sr.reveal(`.container-steps-text:not(:nth-last-child(-n+2))`, {
  delay: 1200,
  distance: "100px",
  interval: 100,
  mobile: false,
})

sr.reveal(`.about-image`, {
  origin: "left",
  mobile: false,
})
sr.reveal(`.about-text`, {
  origin: "right",
  mobile: false,
})

sr.reveal(`#skills`, {
  mobile: false,
})
sr.reveal(`.card-wrap`, {
  delay: 400,
  distance: "100px",
  interval: 100,
  mobile: false,
})

sr.reveal(`.sub-skills`, {
  delay: 600,
  mobile: false,
})

sr.reveal(`#projects`, {
  delay: 200,
  mobile: false,
})

sr.reveal(`.customers-image`, {
  delay: 300,
  origin: "left",
  mobile: false,
})
sr.reveal(`.customers-text`, {
  delay: 300,
  origin: "right",
  mobile: false,
})

sr.reveal(`#contact .container *`, {
  delay: 200,
  interval: 200,
  mobile: false,
})
